.base-btn {
  display: inline-block;
  text-align: center;
  border: none;
  box-shadow: 0px 4px 18px -8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  z-index: 0;
}
.base-btn a.bb-link {
  text-decoration: none;
}

.base-btn.bb-green {
  background: #5B8811;
  color: #FFFFFF;
  border: 1px solid #5B8811;
}

.base-btn.bb-primary {
  background: #0065F2;
  color: #FFFFFF;
  border: 1px solid #0065F2;
}
.base-btn.bb-primary a.bb-link {
  color: #FFFFFF;
}
.base-btn.bb-primary.bb-outline {
  background: #FFFFFF;
  color: #0065F2;
  border: 1px solid #0065F2;
}
.base-btn.bb-primary.bb-outline a.bb-link {
  color: #0065F2;
}

.base-btn.bb-black {
  background: none;
  color: #4D4D4D;
  border: none;
  box-shadow: none;
}
.base-btn.bb-black a.bb-link {
  color: #4D4D4D;
}
.base-btn.bb-black:not(.bb-link).bb-outline {
  background: #FFFFFF;
  color: #4D4D4D;
  border: 1px solid #4D4D4D;
}
.base-btn.bb-black.bb-outline a.bb-link {
  color: #4D4D4D;
}

.base-btn.bb-red {
  background: #FFFFFF;
  color: #E93A3A;
  border: none;
  box-shadow: none;
}
.base-btn.bb-red a.bb-link {
  color: #E93A3A;
}
.base-btn.bb-red.bb-outline {
  background: #FFFFFF;
  color: #E93A3A;
  border: 1px solid #E93A3A;
}
.base-btn.bb-red.bb-outline a.bb-link {
  color: #E93A3A;
}

.base-btn.bb-large {
  width: 100%;
}

.base-btn.bb-size-lg {
  min-width: 120px;
  height: 40px;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
}

.base-btn.bb-size-md {
  min-width: 92px;
  height: 40px;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
}

@media screen and (max-width: 480px) {
  .base-btn.bb-size-md {
    min-width: 80px;
    margin-bottom: 5px;
  }
}

.base-btn.bb-size-sm {
  min-width: 74px;
  height: 32px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
}

.base-btn.bb-icon-left {
  position: relative;
  padding-left: 30px;
  padding-right: 10px;
}

.base-btn.bb-icon-left > .icon-left {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 8px;
  top: 10px;
}
